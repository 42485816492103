/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/pickadate@3.6.3/lib/compressed/themes/classic.css
 * - /npm/pickadate@3.6.3/lib/compressed/themes/classic.date.css
 * - /npm/bootstrap@3.3.7/dist/css/bootstrap.min.css
 * - /npm/bootstrap@3.3.7/dist/css/bootstrap-theme.min.css
 * - /npm/animate.css@3.5.2/animate.min.css
 * - /npm/hover.css@2.1.1/css/hover-min.min.css
 * - /npm/cookieconsent@3.0.4/build/cookieconsent.min.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
